
import Vue from "vue";

import ActivitiesMixin from "@/components/ActivitiesView/ActivitiesMixin.vue";

interface IUserActivitiesData {
  error: string;
  message: string;
}

export default Vue.extend({
  name: "userActivitiesView",
  mixins: [ActivitiesMixin],

  data(): IUserActivitiesData {
    return {
      error: "",
      message: ""
    };
  },
  computed: {
    queryOverride(): any {
      return {
        name__in: `
        ADD_BULK_USER_SUCCESS,
        ADD_BULK_USER_FAILURE,
        BULK_AGENT_BANNING_OR_RESTRICTION,
        ADD_USER_FAILURE,
        ADD_USER_SUCCESS,
        DELETE_USER_FAILURE,
        DELETE_USER_SUCCESS,
        UPDATE_USER_FAILURE,
        UPDATE_USER_SUCCESS,
        BULK_AGENT_BANNING_OR_RESTRICTION,
        RESTORE_USER_SUCCESS,
        RESTORE_USER_FAILURE,
        USER_RESTRICTION_SUCCESS,
        USER_RESTRICTION_FAILURE,
        UNBANNED_USER_SUCCESS,
        UNBANNED_USER_FAILURE,
        BAN_USER_SUCCESS,
        BAN_USER_FAILURE,
        DERESTRICT_USER_SUCCESS,
        DERESTRICT_USER_FAILURE
        `.replace(/\s/g, ""),
        activityType: "Activity"
      };
    }
  }
});
